import React, { useEffect } from "react";
import { Outlet, NavLink, Link } from "react-router-dom";
import useSessionStorage from "../../hook/useSessionStorage";
import useWindowSize from "../../hook/UseWindowSize";
import github from "../../assets/github.svg";
import itsLogo from "../../assets/its-logo.svg";
import robotLogo from "../../assets/robot-solid.svg";
import styles from "./Layout.module.css";
import { UserChatMessage } from "../../components/UserChatMessage";

const Header = () => {
    return (<header className={styles.header} role={"banner"}>
        <div className={styles.headerContainer}>
            <Link to="/" className={styles.headerTitleContainer}>
                <img src={itsLogo} alt="HKU ITS Chatbot" aria-label="HKU ITS Chatbot Version 1.0" className={styles.itsLogo} />
                <img src={robotLogo} alt="HKU ITS Chatbot" aria-label="HKU ITS Chatbot Version 1.0" className={styles.robotLogo} />
                <p className={styles.robotDescr}>HKU ITS Chatbot</p>
                {/*
                <h3 className={styles.headerTitle}>
                    GPT + Enterprise data | Sample <span className={styles.headerTitleHighlight}>Version 2.4</span>
                </h3>
            */}
            </Link>
            <nav>
                <ul className={styles.headerNavList}>
                    {/* <li>
                    <NavLink to="/" className={({ isActive }) => (isActive ? styles.headerNavPageLinkActive : styles.headerNavPageLink)}>
                        Chat
                    </NavLink>
                </li>
                <li className={styles.headerNavLeftMargin}>
                    <NavLink to="/qa" className={({ isActive }) => (isActive ? styles.headerNavPageLinkActive : styles.headerNavPageLink)}>
                        Ask a question
                    </NavLink>
                </li> */}
                    {/*
                    <li className={styles.headerNavLeftMargin}>
                        <a href="https://aka.ms/entgptsearch" target={"_blank"} title="Github repository link">
                            <img
                                src={github}
                                alt="Github logo"
                                aria-label="Link to github repository"
                                width="20px"
                                height="20px"
                                className={styles.githubLogo}
                            />
                        </a>
                    </li>
                */}
                </ul>
            </nav>
            {/*<h4 className={styles.headerRightText}>Azure OpenAI + Cognitive Search</h4>*/}
        </div>
    </header>)
}

const Layout = () => {
    const confrimTNC = useSessionStorage("confrimTNC", false);

    const {width, height} =useWindowSize();

    useEffect(()=>{

        window.addEventListener('load', (event) => {
            window.removeEventListener('beforeunload', () => { confrimTNC[1](false)});
        });

        return () => {
            //window.removeEventListener('beforeunload', () => { confrimTNC[1](false)});
        };
    })

    const confirm = () =>{
        confrimTNC[1](true);
    }
    if (!confrimTNC[0]) {

        return (<><Header />
                <div className={styles.container}>
                    <div className={styles.layout}>
                        <div className={styles.disclaimerContainer}>
                            <div className={styles.wrapper} >
                                 <div className={styles.disclaimerHeader}>Disclaimer</div>
                                <div className={styles.disclaimer}>
                                    <p>This Chatbot operates on the GPT-3 AI language model, developed by OpenAI, and is intended exclusively for informational purposes. It is designed to respond solely to inquiries related to the services provided by our Information Technology Service. While diligent efforts have been made to provide accurate and up-to-date information, please be aware that the Chatbot's responses may not always reflect the latest events or developments, and its accuracy cannot be guaranteed to be 100%.</p>
                                </div>
                                <button className={styles.disclaimerButton} onClick={confirm}>Agree</button>
                            </div>
                        </div>
                    </div>
                </div>
                </>);

    }

    return (
        <div className={styles.layoutContent}>
            <Header />
            <Outlet />
        </div>
    );
};

export default Layout;
